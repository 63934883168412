<template>
  <div :style="loading ? 'user-select: none; pointer-events: none;' : ''">
    <div v-if="loading" class="loader">
      <div class="dot dot1"></div>
      <div class="dot dot2"></div>
      <div class="dot dot3"></div>
      <div class="dot dot4"></div>
    </div>
    <div class="row">
      <div class="col-sm-8">
        <h5 class="title">Pytania</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-body row">
              <div class="col-sm-4" align="center" v-on:change="switchStatus">
                <el-radio-group v-model="radios.radio" size="medium">
                  <el-radio-button id="questions_active_radio" label="ACTIVE">Aktywne</el-radio-button>
                  <el-radio-button id="questions_inactive_radio" label="INACTIVE">Nieaktywne</el-radio-button>
                </el-radio-group>
              </div>
              <div class="col-sm-4" align="center" v-on:change="switchStatus">
                <el-checkbox
                  id="questions_subquestions_checkbox"
                  v-model="checkboxes.subquestion"
                  label="Podpytania"
                  border
                  size="medium"
                ></el-checkbox>
              </div>
              <div class="col-sm-4">
                <el-checkbox-group v-model="owner">
                  <el-checkbox-button v-for="city in owners" :label="city" :key="city">{{ city }}</el-checkbox-button>
                </el-checkbox-group>
              </div>
              <div class="col-sm-4">
                <fg-input
                  class="input-sm"
                  id="questions_search_input"
                  placeholder="Szukaj"
                  v-model="searchQuery"
                  addon-right-icon="nc-icon nc-zoom-split"
                >
                </fg-input>
              </div>
            </div>
            <el-table :data="queriedData" stripe style="width: 100%" @sort-change="sortChange"
                      v-on:filter-change="changeFilter($event)">
              <el-table-column type="expand">
                <template v-slot="props">
                  <div class="row">
                    <div class="col-sm-6">
                      <label>Możliwe odpowiedzi</label>
                      <table class="table table-borderless">
                        <tbody>
                        <tr v-for="answer in props.row.answers" :key="answer.answer_id">
                          <td width="40">{{ answer.answer_position }})</td>
                          <td width="70">[{{ answer.answer_id }}]</td>
                          <td>{{ answer.answer_content }}</td>
                          <td
                            class="hoverClass"
                            @click="searchQuery = answer.next_question_id.toString()"
                            v-if="
                                answer.next_question_id !== null &&
                                (status === '' || questions[answer.next_question_id].question_is_active === status)
                              "
                          >
                            <el-tooltip
                              class="item"
                              effect="light"
                              :content="questions[answer.next_question_id].question_content.substr(0, 50)"
                              placement="top"
                              :open-delay="500"
                            >
                              <i class="fa fa-level-down" aria-hidden="true"></i>
                            </el-tooltip>
                            Pytanie podrzędne: {{
                              answer.next_question_id + " (" + questions[answer.next_question_id].question_is_active + ")"
                            }}
                          </td>
                          <td
                            v-if="
                                answer.next_question_id !== null && status !== '' && questions[answer.next_question_id].question_is_active !== status
                              "
                          >
                            <el-tooltip
                              class="item"
                              effect="light"
                              :content="questions[answer.next_question_id].question_content.substr(0, 50)"
                              placement="top"
                              :open-delay="500"
                            >
                              <i class="fa fa-level-down" aria-hidden="true"></i>
                            </el-tooltip>
                            Pytanie podrzędne: {{
                              answer.next_question_id + " (" + questions[answer.next_question_id].question_is_active + ")"
                            }}
                          </td>
                          <td>
                            <p-button
                              v-if="!answer.is_paused"
                              :id="'answer_pause_button_' + props.$index"
                              type="warning"
                              size="sm"
                              icon
                              @click="showSwal(props.$index, answer, 'answer')"
                            >
                              <i v-if="!answer.is_paused" class="fa fa-pause"></i>
                            </p-button>
                            <p-button
                              v-if="answer.is_paused"
                              :id="'answer_pause_button_' + props.$index"
                              type="success"
                              size="sm"
                              icon
                              @click="showSwal(props.$index, answer, 'answer')"
                            >
                              <i v-if="answer.is_paused" class="fa fa-play"></i>
                            </p-button>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <label v-if="subquestionParents[props.row.question_id] !== undefined">Pytania nadrzędne</label>
                      <table class="table table-borderless">
                        <tbody>
                        <tr v-for="question in subquestionParents[props.row.question_id]" :key="question">
                          <td
                            v-if="status === '' || questions[question].question_is_active === status"
                            class="hoverClass"
                            @click="searchQuery = question.toString()"
                            width="100"
                          >
                            <el-tooltip
                              class="item"
                              effect="light"
                              :content="questions[question].question_content.substr(0, 50)"
                              placement="top"
                              :open-delay="500"
                            >
                              <i class="fa fa-level-up" aria-hidden="true"></i>
                            </el-tooltip>
                            {{ question + " (" + questions[question].question_is_active + ")" }}
                          </td>
                          <td v-if="status !== '' && questions[question].question_is_active !== status" width="100">
                            <el-tooltip
                              class="item"
                              effect="light"
                              :content="questions[question].question_content.substr(0, 50)"
                              placement="top"
                              :open-delay="500"
                            >
                              <i class="fa fa-level-up" aria-hidden="true"></i>
                            </el-tooltip>
                            {{ question + " (" + questions[question].question_is_active + ")" }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-sm-6">
                      <label>Projekty</label>
                      <table class="table table-borderless">
                        <tbody>
                        <tr v-for="project in props.row.projects" :key="project.project_id">
                          <td width="40">[{{ project.project_id }}]</td>
                          <td>{{ project.project_name }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="ID" property="question_id" width="80" sortable="custom"></el-table-column>
              <el-table-column label="Treść pytania" property="question_content_html" min-width="300" sortable="custom">
                <template v-slot="props">
                  <div style="padding: 12px 0px; word-break: keep-all">
                    <el-tooltip
                      v-if="!props.row.subquestion && props.row.parent"
                      class="item"
                      effect="light"
                      content="Pytanie nadrzędne"
                      placement="top"
                      :open-delay="500"
                    >
                      <i class="fa fa-level-up" aria-hidden="true"></i>
                    </el-tooltip>
                    <el-tooltip v-if="props.row.subquestion" class="item" effect="light" content="Podpytanie"
                                placement="top" :open-delay="500">
                      <i class="fa fa-level-down" aria-hidden="true"></i>
                    </el-tooltip>
                    {{ props.row.question_content_html }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                label="Typ"
                property="question_type_alias"
                :filter-multiple="false"
                :filters="this.filterTypes"
                :filter-method="filterHandler"
                min-width="100"
              ></el-table-column>
              <el-table-column
                property="template_name"
                :filter-multiple="false"
                :filters="Object.values(filterTemplates)"
                :filter-method="filterHandler"
                width="80"
              >
                <template v-slot="header">
                  <i class="fa fa-code" aria-hidden="true"></i>
                </template>
                <template v-slot="props">
                  <el-tooltip class="item" effect="light" :content="filterTemplates[props.row.template_name].text"
                              placement="top" :open-delay="500">
                    <i :class="getTemplateIcon(props.row.template_name)" aria-hidden="true"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="Priorytet" property="priority" align="center" min-width="125" sortable="custom">
                <template v-slot="props">
                  <div v-if="props.row.subquestion === 0">{{ props.row.priority }}</div>
                  <div v-else>-</div>
                </template>
              </el-table-column>
              <el-table-column label="Status" property="question_is_active" align="center" width="140"
                               sortable="custom">
                <template v-slot="props">
                  <el-tag v-if="props.row.subquestion === 0" effect="dark" :type="tagType(props.row.question_is_active)"
                          size="small">{{
                      props.row.question_is_active
                    }}
                  </el-tag>
                  <div v-else>-</div>
                </template>
              </el-table-column>
              <el-table-column label="Ko-Work" align="center" width="100">
                <template v-slot="props">
                  <div class="questionKoList" v-if="sendsKoByQuestionId(props.row.question_id).length>0">
                    <el-button icon="el-icon-edit-outline" circle type="success"
                               @click="isDialogKoVisible(props.row.question_id)"></el-button>
                    <el-dialog title="Wysyłki korejestracyjne włącz/wyłącz" append-to-body
                               :visible.sync="props.row.visible">
                      <el-table :data="sendsKoByQuestionId(props.row.question_id)" row-key="sendKoId"
                                default-expand-all>

                        <el-table-column property="sendKoId" label="ID" width="100"></el-table-column>
                        <el-table-column property="name" label="Nazwa"></el-table-column>
                        <el-table-column align="center">
                          <template v-slot:header>
                            <el-tooltip content="Wyłącz wszystkie wysyłki" placement="top" v-if="editPermittedKoSends">
                              <div
                                @click="koChangeWoring(sendKoIdsByQuestionId(props.row.question_id,'sendKoId'),props.row.question_id,0)"
                                class="el-icon-ko-question-status el-icon-video-pause"
                              ></div>
                            </el-tooltip>
                            <el-tooltip content="Włącz wszystkie wysyłki" placement="top" v-if="editPermittedKoSends">
                              <div
                                @click="koChangeWoring(sendKoIdsByQuestionId(props.row.question_id,'sendKoId'),props.row.question_id,1)"
                                class="el-icon-ko-question-status el-icon-video-play"></div>
                            </el-tooltip>
                          </template>
                          <template v-slot="props">
                            <el-tooltip content="Wyłącz wysyłkę" placement="top"
                                        v-if="props.row.isWorking===1 && editPermittedKoSends">
                              <div
                                @click="koChangeWoring([props.row.sendKoId],props.row.questionId,0)"
                                class="el-icon-ko-question-status el-icon-video-pause"
                              ></div>
                            </el-tooltip>
                            <el-tooltip content="Włącz wysyłkę" placement="top"
                                        v-if="props.row.isWorking===0 && editPermittedKoSends">
                              <div
                                @click="koChangeWoring([props.row.sendKoId],props.row.questionId,1)"
                                class="el-icon-ko-question-status el-icon-video-play"
                              ></div>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-dialog>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Pauza" fixed="right" align="center"
                               v-if="editPermitted && this.status === 'ACTIVE'" width="90">
                <template v-slot="props">
                  <template v-if="props.row.subquestion === 0">
                    <p-button
                      v-if="props.row.question_is_active === 'ACTIVE'"
                      :disabled="props.row.auto_pause === 1"
                      :id="'questions_pause_button_' + props.$index"
                      type="warning"
                      size="sm"
                      icon
                      @click="showSwal(props.$index, props.row, 'question')"
                    >
                      <i v-if="props.row.question_is_active === 'ACTIVE'" class="fa fa-pause"></i>
                    </p-button>
                    <p-button
                      v-if="props.row.question_is_active === 'PAUSE'"
                      :disabled="props.row.auto_pause === 1"
                      :id="'questions_pause_button_' + props.$index"
                      type="success"
                      size="sm"
                      icon
                      @click="showSwal(props.$index, props.row, 'question')"
                    >
                      <i v-if="props.row.question_is_active === 'PAUSE'" class="fa fa-play"></i>
                    </p-button>
                  </template>
                  <template v-else>
                    <div>-</div>
                  </template>
                </template>

              </el-table-column>
              <el-table-column align="center" v-if="editPermitted" width="80">
                <template v-slot:header>
                  <router-link to="/questions/question_form">
                    <p-button type="success" icon round>
                      <i id="questions_add_button" class="fa fa-plus"></i>
                    </p-button>
                  </router-link>
                </template>
                <template v-slot="props">
                  <p-button type="success" size="sm" icon :id="'questions_edit_button_' + props.$index"
                            @click="handleEdit(props.$index, props.row)">
                    <i class="fa fa-edit"></i>
                  </p-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="card-body row">
            <div class="col-sm-4 pagination-info">
              <p class="category">Pokazuje od {{ from + 1 }} do {{ to }} z {{ total }} pozycji</p>
            </div>
            <div class="col-sm-4" align="center" v-on:change="switchStatus">
              <el-radio-group v-model="radios.radio" size="medium">
                <el-radio-button id="questions_archive_radio" label="ARCHIVE">Archiwalne</el-radio-button>
                <el-radio-button id="questions_all_radio" label="ALL">Wszystkie</el-radio-button>
              </el-radio-group>
            </div>
            <div class="col-sm-4">
              <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top bottom="50px" right="50px" visibleoffset="100">
      <p-button type="primary" round><i class="fa fa-chevron-up"></i></p-button>
    </back-to-top>
  </div>
</template>

<script>
import Vue from "vue";

import Swal from "sweetalert2";
import BackToTop from "vue-backtotop";

// ui components
import {
  Table,
  TableColumn,
  Select,
  Option,
  Switch,
  Tag,
  Scrollbar,
  Badge,
  Tooltip,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  Button,
  Dialog,
  CheckboxGroup,
  CheckboxButton
} from "element-ui";
import {Tabs, TabPane, Card} from "src/components/UIComponents";
import PPagination from "src/components/UIComponents/Pagination.vue";

// vuex actions
import {LOAD_QUESTIONS, QUESTIONS_INVALID} from "src/store/actions/questions/questions";
import {LOAD_ANSWERS, ANSWERS_INVALID} from "src/store/actions/questions/answers";
import {
  LOAD_QUESTION_PROJECTS_LIST,
  QUESTION_PROJECTS_LIST_INVALID
} from "src/store/actions/questions/question_projects_list";
import {LOAD_SUBQUESTION_PARENTS, SUBQUESTION_PARENTS_INVALID} from "src/store/actions/questions/subquestion_parents";
import {SAVE_PAUSE_QUESTION} from "src/store/actions/questions/pause_question";
import {SAVE_PAUSE_ANSWER} from "src/store/actions/questions/pause_answer";
import {
  LOAD_KO_QUESTIONS,
  INVALID_KO_QUESTIONS,
  GET_KO_QUESTIONS,
  SUCCESS_KO_QUESTIONS
} from "@/store/actions/korejestracja/ko_questions";
import {GET_KO_SENDS, SAVE_KO_SENDS,} from "@/store/actions/korejestracja/ko_sends";

// event bus
import {eventBus} from "src/main";

import * as priv from "src/privileges.js";
import {GET_CUSTOMER_EFFICIENCY, LOAD_CUSTOMER_EFFICIENCY} from "@/store/actions/sales/customer_efficiency";
import {LOAD_CUSTOMER_EFFICIENCY_EXCEL_DISPLAY} from "@/store/actions/sales/customer_efficiency_excel";
import {PRIV_EDIT_KOSENDS} from "src/privileges.js";
import projects from "@/store/modules/questions/projects";

Vue.use(Table);
Vue.use(TableColumn);

export default {
  components: {
    [Tag.name]: Tag,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
    [Scrollbar.name]: Scrollbar,
    [Badge.name]: Badge,
    [Tooltip.name]: Tooltip,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    PPagination,
    TabPane,
    Tabs,
    Card,
    BackToTop,
  },
  data() {
    return {
      dialogKoVisible: [],
      owner: ['MM', '4M','NO-OWNER'],
      owners: ['MM', '4M','NO-OWNER'],
      questionOwners: [],
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["question_id", "question_content", "question_type", "template_name"],
      radios: {
        radio: "ACTIVE",
      },
      checkboxes: {
        subquestion: false,
      },
      sort: {
        prop: "question_id",
        order: "ascending",
      },
      status: "ACTIVE",
      loading: true,
      filterTypes: [
        {text: "CO-REG", value: "CO-REG"},
        {text: "PROFILE", value: "PROFILE"},
        {text: "QUIZ", value: "QUIZ"},
        {text: "OPT-IN", value: "OPT-IN"},
      ],
      filterTemplates: {
        defaultSelect: {text: "Select", value: "defaultSelect"},
        defaultRadio: {text: "Radio", value: "defaultRadio"},
        defaultCheckbox: {text: "Checkbox", value: "defaultCheckbox"},
        defaultTextarea: {text: "Textarea", value: "defaultTextarea"},
        defaultTextbox: {text: "Textbox", value: "defaultTextbox"},
      },
      activeTypeFilter: "",
      activeTemplateFilter: "",
      pauseFilter: "PAUSE",
    };
  },
  computed: {
    questionsArray() {
      let getter = Object.values(this.$store.getters.getQuestions);
      let type_map = {"CO-REGISTRATION": "CO-REG", "DOUBLEOPT-IN": "OPT-IN"};
      if (this.$store.state.questions.loading_status === "success") {
        getter.map((row) => {
          row.question_type_alias = type_map[row.question_type] || row.question_type;
        });
        let filteredData = getter.filter(
          (row) =>
            (this.activeTypeFilter === "" || row.question_type_alias === this.activeTypeFilter) &&
            (this.activeTemplateFilter === "" || // no filter
              row.template_name === this.activeTemplateFilter) &&
            (this.status === "" || row.question_is_active === this.status || row.question_is_active === this.pauseFilter) &&
            (this.checkboxes.subquestion === true || row.subquestion === 0),
        );
        return filteredData;
      }
      return;
    },
    questions() {
      return this.$store.getters.getQuestions;
    },
    questionProjectsList() {
      return this.$store.getters.getQuestionProjectsList;
    },
    answers() {
      return this.$store.getters.getAnswers;
    },
    subquestionParents() {
      let getter = this.$store.getters.getSubquestionParents;
      let result = {};
      for (let i in getter) {
        result[i] = Object.keys(getter[i]);
      }
      return result;
    },
    sendsKo() {
      let result = [];
      if (this.$store.state.ko_questions.loading_status === "success") {
        result = this.$store.getters[GET_KO_QUESTIONS];
      }
      return result;
    },
    sendsKoSave() {
      let result = [];
      if (this.$store.state.ko_sends.saving_status === "saved") {
        result = this.$store.getters[GET_KO_SENDS];
      }
      return result;
    },
    tableData() {
      const questions = this.questionsArray;
      const answers = this.answers;
      const questionProjectsList = this.questionProjectsList;
      let data = {};
      for (let id in questions) {
        data[questions[id].question_id] = questions[id];
        data[questions[id].question_id].answers = answers[questions[id].question_id];
        data[questions[id].question_id].visible = false;
        data[questions[id].question_id].projects = questionProjectsList[questions[id].question_id];
        if (data[questions[id].question_id].projects !== undefined && !data[questions[id].question_id].projects.find(obj => this.owner.includes(obj.Owner))) {
          delete( data[questions[id].question_id]);
          continue;
        }else if(data[questions[id].question_id].projects === undefined && !this.owner.includes('NO-OWNER')){
          delete( data[questions[id].question_id]);
          continue;
        }


        // data[questions[id].id].answers.push({answer_id: questions[id].answer_id, answer_position: questions[id].position, answer_content: questions[id].answer_content, next_question_id: questions[id].next_question_id});

        if (data[questions[id].question_id].answers) {
          data[questions[id].question_id].answers = Object.values(data[questions[id].question_id].answers).sort((a, b) =>
            a.answer_position > b.answer_position ? 1 : -1,
          );
        }

        data[questions[id].question_id].question_content_html = data[questions[id].question_id].question_content
          .replace(/<br>?/gm, "\n")
          .replace(/<[^>]*>?/gm, " ")
          .replace(/[ ]{2,}?/gm, " ");
      }

      data = Object.values(data).map((row) => Object.assign({}, row)); // przypisanie do zmiennej pomocniczej tablicy baz wyjątków
      data.sort((a, b) => (a.priority < b.priority ? 1 : -1));
      return data;
    },
    editPermitted() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      return userPrivileges.includes(priv.PRIV_EDIT_ADMIN_QUESTIONS);
    },
    editPermittedKoSends() {
      const userPrivileges = this.$store.getters.getProfile.privileges;
      console.log('perko', userPrivileges.includes(priv.PRIV_EDIT_KOSENDS));
      return userPrivileges.includes(priv.PRIV_EDIT_KOSENDS);
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length;
        return this.tableData.slice(this.from, this.to);
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          let rowValueLowerCase = row[key].toString().toLowerCase();
          if (
            (rowValue.includes && rowValue.includes(this.searchQuery)) ||
            (rowValueLowerCase.includes && rowValueLowerCase.includes(this.searchQuery))
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
  },
  methods: {
    changesKoQuestion(keyStateToChangeList, datasChange) {
      let data = [];
      for (let i = 0; i < this.sendsKo.length; i++) {
        data[i] = {};
        for (let j in this.sendsKo[i]) {
          if (Array.isArray(this.sendsKo[i][j])) {
            data[i][j] = [];
            for (let k in this.sendsKo[i][j]) {
              data[i][j][k] = {};

              for (let l in this.sendsKo[i][j][k]) {
                data[i][j][k][l] = this.sendsKo[i][j][k][l];
              }
            }
          } else {
            data[i][j] = this.sendsKo[i][j];
          }
        }
      }
      const filters = {};
      for (let key in keyStateToChangeList) {
        let objectChange = keyStateToChangeList[key];
        if (objectChange.hasOwnProperty('keySendKo')) {
          data[objectChange.keySendKo].isWorking = datasChange;
        }

        if (objectChange.hasOwnProperty('parentKey')) {
          data[objectChange.parentKey].children[objectChange.childKey].isWorking = datasChange;
        }
      }
      this.$store.commit(SUCCESS_KO_QUESTIONS, {data, filters});
    },
    async koChangeWoring(sendKoId, questionId, isWorking) {
      let filters = {
        sendKoId: sendKoId,
        isWorking: isWorking
      };
      await this.$store
        .dispatch(SAVE_KO_SENDS, filters)
        .then(() => {
          let message = 'Wczytano dane';

          let savedResponse = this.$store.getters.GET_KO_SENDSModifiedRecords;

          if (savedResponse.status === true) {
            message = savedResponse.info;

            let keyStateToChangeList = [];

            this.sendsKo.map((element, index) => {

              if (element.questionId === questionId) {
                let result = {};
                if (sendKoId.includes(element.sendKoId)) {
                  result.keySendKo = index;
                }
                if (element.hasOwnProperty('children')) {
                  for (let keyChild in element.children) {
                    if (sendKoId.includes(element.children[keyChild].sendKoId)) {
                      result.parentKey = index;
                      result.childKey = keyChild;
                    }
                  }
                }
                if (Object.keys(result).length > 0) {
                  keyStateToChangeList.push(result);
                }
              }

            });
            if (keyStateToChangeList.length > 0) {
              this.changesKoQuestion(keyStateToChangeList, isWorking);
            }
          }

          eventBus.$emit("notify", {
            message: message,
            type: "success",
          });
        }).catch((error) => {
          let msg = 'Błąd wczytywania danych!';

          if (error.response.status === 403) {
            msg = 'Brak uprawnień do Modułu Korejestracja';
          } else {
            msg = error.response.data.description;
          }
          eventBus.$emit("notify", {
            message: msg,
            type: "warning",
          })
        });

    },
    isDialogKoVisible(questionId) {
      let koQuestionIndex = this.tableData.findIndex((item) => item.question_id === questionId);
      let result = false;
      if (koQuestionIndex !== -1) {
        if (this.tableData[koQuestionIndex].visible === false) {
          this.tableData[koQuestionIndex].visible = true;
        } else {
          this.tableData[koQuestionIndex].visible = false;
        }
        result = this.tableData[koQuestionIndex].visible;
      }
      return result;
    },
    async loadData(force, force_all) {
      this.loading = true;
      const filters = {
        question_status: this.status,
        subquestion: this.checkboxes.subquestion,
      };

      if (force) {
        this.$store.commit(QUESTIONS_INVALID);
        this.$store.commit(ANSWERS_INVALID);
        this.$store.commit(INVALID_KO_QUESTIONS);
      }

      if (force_all) {
        this.$store.commit(QUESTIONS_INVALID);
        this.$store.commit(ANSWERS_INVALID);
        this.$store.commit(QUESTION_PROJECTS_LIST_INVALID);
        this.$store.commit(SUBQUESTION_PARENTS_INVALID);
        this.$store.commit(INVALID_KO_QUESTIONS);
      }
      this.$store.dispatch(LOAD_KO_QUESTIONS, {}).catch((error) => {
        let msg = 'Błąd wczytywania danych!';

        if (error.response.status === 403) {
          msg = 'Brak uprawnień do Modułu pytania korejestracyjnych';
        }
        eventBus.$emit("notify", {
          message: msg,
          type: "warning",
        })
      });

      this.$store.dispatch(LOAD_QUESTION_PROJECTS_LIST, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_SUBQUESTION_PARENTS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      this.$store.dispatch(LOAD_ANSWERS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });

      await this.$store.dispatch(LOAD_QUESTIONS, {}).catch((error) => {
        eventBus.$emit("notify", {
          message: "Błąd wczytywania danych!",
          type: "warning",
        });
      });
      this.loading = false;
    },
    handleEdit(index, row) {
      this.$router.push("/questions/question_form/" + row.question_id);
    },
    tagType(value) {
      switch (value) {
        case "ACTIVE":
          return "success";
        case "INACTIVE":
          return "warning";
        case "ARCHIVE":
          return "danger";
        default:
          return "info";
      }
    },
    sortChange(obj) {
      this.sort = {
        prop: obj.prop,
        order: obj.order,
      };
      if (obj.order === "descending") {
        if (obj.prop === "question_is_active" || obj.prop === "question_content_html") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() > b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => b[obj.prop] - a[obj.prop]);
        }
      } else if (obj.order === "ascending") {
        if (obj.prop === "question_is_active" || obj.prop === "question_content_html") {
          this.tableData.sort((a, b) => (a[obj.prop].toLowerCase() < b[obj.prop].toLowerCase() ? 1 : -1));
        } else {
          this.tableData.sort((a, b) => a[obj.prop] - b[obj.prop]);
        }
      } else {
        this.tableData.sort((a, b) => b.priority - a.priority);
      }
      this.searchQuery += " ";
      this.searchQuery = this.searchQuery.slice(0, -1);
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    changeFilter(payload) {
      let filter = Object.values(payload);
      let columnName = Object.keys(payload)[0];
      let typeName = document.querySelector("." + columnName).firstChild.textContent;
      let columnType = typeName === "Typ" ? "type" : "template";
      if (filter[0][0] === undefined) {
        if (columnType === "type") {
          this.activeTypeFilter = "";
        } else {
          this.activeTemplateFilter = "";
        }
      } else {
        if (columnType === "type") {
          this.activeTypeFilter = filter[0][0];
        } else {
          this.activeTemplateFilter = filter[0][0];
        }
      }
    },
    switchStatus() {
      this.status = this.radios.radio;
      if (this.status === "ALL") {
        this.status = "";
      }
      if (this.status === "ACTIVE") {
        this.pauseFilter = "PAUSE";
      } else {
        this.pauseFilter = "";
      }
    },
    showSwal(index, row, type) {
      var varThis = this;
      var index = index;
      var row = row;

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger btn-fill",
          cancelButton: "btn btn-light btn-fill",
        },
        buttonsStyling: false,
      });

      let text = "";
      let confirmButton = "";
      if (type === "question") {
        text = "Pytanie " + row.question_id + " zostanie " + (row.question_is_active === "ACTIVE" ? "zapauzowane" : "aktywowane");
        confirmButton = "Tak, " + (row.question_is_active === "ACTIVE" ? "zapauzuj" : "aktywuj");
      } else {
        text = "Odpowiedź " + row.answer_id + " zostanie " + (!row.is_paused ? "zapauzowana" : "aktywowana");
        confirmButton = "Tak, " + (!row.is_paused ? "zapauzuj" : "aktywuj");
      }

      swalWithBootstrapButtons
        .fire({
          title: "Czy jesteś pewien?",
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: confirmButton,
          cancelButtonText: "Anuluj",
          reverseButtons: true,
        })
        .then((res) => {
          if (res.isConfirmed) {
            varThis.changePause(index, row, type);
          }
        })
        .catch(() => {
          return;
        });
    },
    async changePause(index, row, type) {
      let record = {
        status: "",
        question_id: null,
        answer_id: null,
      };

      if (type === "question") {
        record.status = row.question_is_active === "ACTIVE" ? "PAUSE" : "ACTIVE";
        record.question_id = row.question_id;
      } else {
        record.status = row.is_paused ? 0 : 1;
        record.answer_id = row.answer_id;
      }

      const dispatcher = type === "question" ? SAVE_PAUSE_QUESTION : SAVE_PAUSE_ANSWER;
      const modifiedRecords =
        type === "question" ? this.$store.getters.getPauseQuestionsModifiedRecords : this.$store.getters.getPauseAnswerModifiedRecords;

      await this.$store.dispatch(dispatcher, record).catch((error) => {
        //console.log(error.response);
        eventBus.$emit("notify", {
          message: "Błąd zapisu danych!",
          type: "warning",
        });
      });

      if (modifiedRecords === true) {
        eventBus.$emit("notify", {
          message: "Rekord został zmodyfikowany",
          type: "success",
        });

        this.loadData(true, false);
      }
    },
    getTemplateIcon(name) {
      switch (name) {
        case "defaultSelect":
          return "fa fa-caret-square-o-down";
        case "defaultRadio":
          return "fa fa-dot-circle-o";
        case "defaultCheckbox":
          return "fa fa-check-square-o";
        case "defaultTextarea":
          return "fa fa-pencil-square-o";
        case "defaultTextbox":
          return "fa fa-pencil";
        default:
          return "fa fa-square-o";
      }
    },
    sendsKoByQuestionId(questionId) {
      return this.sendsKo.filter((element) => element.questionId === questionId);
    },
    sendKoIdsByQuestionId(questionId, keyToGet) {
      let result = [];
      let sendsKoByQuestionId = this.sendsKoByQuestionId(questionId);

      for (let key in sendsKoByQuestionId) {
        if (sendsKoByQuestionId[key].hasOwnProperty(keyToGet)) {
          result.push(sendsKoByQuestionId[key][keyToGet]);
        }
        if (sendsKoByQuestionId[key].hasOwnProperty('children')) {
          for (let keyChild in sendsKoByQuestionId[key]['children']) {
            result.push(sendsKoByQuestionId[keyChild]['children'][keyChild][keyToGet]);
          }
        }
      }
      return result;
    }
  },
  created() {
    this.loadData(true, false);
    eventBus.$on("forceReload", () => {
      this.loadData(false, true);
    });
  },
  beforeDestroy: function () {
    eventBus.$off("forceReload");
  },
};
</script>

<style lang="scss" scoped>
.has-gutter:hover {
  cursor: pointer;
}

.el-table,
.el-table tr,
.el-table thead th {
  background-color: #fff !important;
}

.hoverClass:hover {
  cursor: pointer;
  text-decoration: underline;
}

.el-icon-ko-question-status {
  font-size: 30px;
  cursor: pointer;
}

.el-icon-ko-question-status.el-icon-video-pause {
  color: #FF9500;
}

.el-icon-ko-question-status.el-icon-video-play {
  color: #00b800;
}

.el
.columnText {
  word-break: keep-all;
}
</style>
